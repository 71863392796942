@import url(https://fonts.googleapis.com/css2?family=Varela&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", "Varela", sans-serif;
  position: relative;
  overscroll-behavior-x: none;
  /* background-color: #f0f2f0; */
}

section[disabled] {
  pointer-events: none;
  opacity: 0.4;
  background: #f5f5f5;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media print {
  @page {
    size: A2 landscape;
  }
  .no-print {
    display: none;
  }
}

.App {
  height: 100vh;
  background-color: white;
}

/*@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");*/

.div-flex {
	display: flex;
	justify-content: space-between;
	margin: 0.8rem;
	margin-top: 20px;
	padding: 0.4rem;
}

.MuiPaper-elevation1 {
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
		0px 0px 0px 0px rgb(0 0 0 / 4%) !important;
}

.div-flex .MuiButton-root:hover {
	background-color: white !important;
}

.btn1 {
	border-width: 0px;
	
	width: 48;
}
/* 
.table {
	width: 100%;
	font-family: "Roboto", sans-serif;
	border: 1px solid black;
}

.card_table {
	margin: 0.8rem;
	padding: 0.8rem 0.5rem;
	box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.67);
}

.thead {
	border: 1px solid black;
}

.R1 > td {
	border: 1px solid black;
	padding: 5px;
	font-size: 14px;
	font-weight: 600;
}

.R2 > td {
	border-top: none;
	border: 1px solid black;
	font-size: 14px;
	padding: 0.5rem;
	font-weight: 600;
}

.R4 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
}

/* Table 2 */
/* 
.tr1 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
	font-weight: 700;
}

.table2_head {
	border: 1px solid black;
}
.tr2 > td {
	font-size: 13px;
	padding: 0.5rem;
	border: 1px solid black;
	font-weight: 700;
}
.tr1 > .td2 {
	width: 50px;
}
.tr1 > .td1 {
	width: 100px;
}

.tr1 > .td3 {
	width: 90px;
}
.tr1 > .td5 {
	width: 90px;
}

.tr1 > .td6 {
	width: 80px;
}

.tr1 > .td3 {
	width: 120px;
}

.tr2 > .td1,
.tr2 {
	width: 100px;
}

.tr2 > .td3,
.td4 {
	width: 90px;
}

.tr3 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
	font-weight: 600;
}

.tr4 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
	font-weight: 600;
}

.tr5 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
}

.thead span {
	font-weight: 600;
} */ 

.view_part_section {
  display: flex;
  height: 93vh;
  flex-direction: column;
  align-items: center;
  background-color: #f0f2f0;
}
.mid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mid {
  height: 93vh;
  background-color: white;
}
.dropdown {
  margin-left: 1rem;
}
.select {
  width: 60px;
}
.card {
  margin-left: 1rem;
  border: 0px;
}
.card p {
  margin-top: 0.6rem;
}
.view_part {
  margin-top: -5rem;
  margin-right: 0rem;
  margin-left: 3.1rem;
  display: flex;
  justify-content: start;
  width: 560px;
  align-items: center;
}
.brief {
  width: 500px;
  margin-top: 3rem;
  margin-left: 1rem;
  font-size: 24px;
}
.view_link {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  padding: 60px;
  height: 82vh;
  overflow-y: scroll;
  justify-items: center;
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 150px;
  height: 150px;
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.733);
  border-radius: 40px;
  background: #fafafa;
  box-shadow: 20px 20px 60px #cccecc, -20px -20px 60px #ffffff;
}
.link:hover {
  background-color: #0033332a;
  color: rgba(0, 0, 0, 0.733);

  transition: background-color 0.4s ease-in-out;
}
.view_part_section .MuiFormControl-root {
  min-width: 400px !important;
}
.view_part_section .MuiSelect-select {
  min-width: 400px !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #006974 !important;
}

.centerflex {
  display: flex;
  justify-content: center;
}

.viewAdminModule_main__rQwxr.viewAdminModule_container__RODyk {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  padding: 3rem;
  font-size: 62.5%;
  font-family: "roboto";
  background-color: #fafafa;
}

.viewAdminModule_container__RODyk div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viewAdminModule_container__RODyk div p {
  font-weight: 600;
}

.specs-field {
  margin-left: 0px !important;
  margin-left: 1rem;
  background-color: white;
  padding-top: 1rem;
}

.specs-field .MuiOutlinedInput-root {
  width: 100% !important;
  /* border-radius: 0px !important; */
}

.failure-field {
  margin-left: 0px !important;
  margin-left: 1em;
  padding-bottom: 0.5rem;
  background-color: white;
}

.failure-field-section {
  display: flex;
}

.failure-field-section > div {
  flex-basis: 25% !important;
}

.failure-field .MuiTextField-root {
  width: 100% !important;
}
.failure-container {
  margin-top: 1.5rem;
  width: 100%;
  margin-left: 0.6rem;
}
.specs-container {
  margin-top: 1.5rem;
  margin-left: 0.6rem;
}

.MuiButton-contained {
  box-shadow: none;
}
.btn_div_flex {
  position: absolute;
  top: -0.2rem;
  right: 5rem;
}

.btn_div_flex > Button {
  margin-left: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 0.8rem;
}

.flexcenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.remove {
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;
  margin-right: 1rem;
  grid-column: 6/-1;
  justify-self: end;
}

.MuiOutlinedInput-input {
  padding: 12px !important;
}

.MuiFormLabel-root {
  font-size: 0.9rem !important;
}

.add_specs .MuiButton-label {
  justify-content: start;
}
.add_failures .MuiButton-label {
  justify-content: start;
}

.add_process {
  margin-top: 1.5rem !important;

  color: black !important;
}

.MuiOutlinedInput-multiline {
  padding: 0px;
  height: 40px;
}
.MuiOutlinedInput-inputMultiline {
  padding: 2px !important;
}

/* 4m Section  */
.m-section .MuiOutlinedInput-root {
  width: 100% !important;
  /* border-radius: 0px !important;
	 */
}

.m-field {
  margin-top: 1.5rem;
  margin-left: 1rem;
  padding: 0.5rem 0.5rem;
}

/* 4m Section  */
.m-section {
  margin-left: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.m-field {
  background-color: white;

  margin-top: 1.5rem;
  margin-left: 0px;
  /* border-radius: 0.5rem; */
  padding: 0.5rem 0.5rem;
}

.makeStyles {
  width: 100% !important;
}
.m-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  width: 100%;
  background-color: white;
  margin-top: 18px;
  margin-left: 1rem;
  padding: 0.8rem 0.3rem;
  padding-bottom: 12px;
  padding-top: 12px;
}

.m-section-main {
  width: 332px;
  margin-left: 1rem;
  margin-top: 0.8rem;
}

.m-section-main .MuiTextField-root {
  margin: 0 !important;
  padding: 0 !important;
  width: 275px;
}
.m-section-main .MuiInputLabel-filled.MuiInputLabel-shrink {
  -webkit-transform: translate(5px, 1px) !important;
          transform: translate(5px, 1px) !important;
}

.divflex {
  display: flex;
  justify-content: space-between;
  /* margin: 0.8rem; */
  margin-bottom: 0px;
  /* margin-top: 20px; */
  padding: 0.75rem 1rem;
  background-color: #161a1d;
  color: white;
  border-top: 1px solid #494949;
  border-bottom: 1px solid #494949;
  font-family: "Nunito";
  letter-spacing: "0.2em";
}

.btnshadow {
  border-radius: 30px;
  width: 150px;
  height: 150px;
  background: #fafafa;
  box-shadow: 15px 15px 31px #cccecc, -15px -15px 31px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnshadow:hover {
  background-color: #0342421f;
  /* width: -webkit-fill-available; */
  transition: background-color 0.4s ease-in-out;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 8px;
}

.header .process_name .select {
  min-width: 750px !important;
}

.select {
  padding: 0.5rem;
  margin-left: 1rem;
}
.header .process_name .MuiSelect-select {
  min-width: 720px !important;
}

.text p {
  margin: 0.8rem;
  font-size: 14px;
  margin-right: 1rem;
}

.grid1 {
  display: grid;
  margin-top: 4rem;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  border-bottom: 1px solid #c80202;
  margin-bottom: 1rem;
}

.grid1 p {
  margin-right: 6rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.6px;
}

.grid2 {
  margin-top: 0.25rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-left: 0.8rem;
}
.grid3 {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.HomePage_main__yVDb_.HomePage_container__gdJMm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  padding: 3rem;
  font-size: 62.5%;
  font-family: "roboto";
  background-color: #fafafa;
}

.HomePage_container__gdJMm div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomePage_container__gdJMm div p {
  font-weight: 600;
}

.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: white;
}
.login {
  padding: 0px 34px;
  background-color: #1d1d1d;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.login form {
  background-color: #fafafa;
  padding: 50px;
}

.login-container-heading {
  margin-bottom: 2rem;
  font-size: 44px;
  font-family: "Nunito", sans-serif;
  color: #fafafa;
}

@media all and (max-width: 800px) {
  .login-container {
    flex-direction: column;
  }
}
@media all and (max-width: 450px) {
  .login form {
    padding: 22px;
  }
  .login-container-heading {
    font-size: 30px;
  }
}

.home {
  display: flex;
  height: 90vh;
  justify-content: space-between;
}
.image {
  display: flex;
  flex: 0.4 1;
  align-items: center;
  justify-items: center;
  background-color: white;
}

.mushin_logo {
  width: 300px;
  height: 300px;
}
.adminSection {
  display: flex;
  flex: 0.6 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fafafa;
}

.user_details {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: -5rem;
  /* position: absolute;
	top: 7rem; */
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: thin;
  color: black;
}

.avatar {
  /* position: relative;
	left: 80px;
	top: -20px; */
}

.task {
  display: flex;
  justify-content: space-evenly;
  /* flex-direction: column; */
  width: 100%;
  height: 20%;
  margin-top: 4.5rem;
  margin-bottom: 4rem;
}

.panel {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #94949454; */
  color: rgba(0, 0, 0, 0.733) !important;
  text-decoration: none !important;
  margin-top: 1rem;
  width: 150px;
  height: 150px;
  border-radius: 40px;
  background: #fafafa;
  box-shadow: 20px 20px 60px #cccecc, -20px -20px 60px #ffffff;
}

.panel:hover {
  background-color: #0342421f;

  transition: background-color 0.4s ease-in-out;
}

.card-btn-para {
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  font-weight: 500;
}

.card-btn-span {
  display: block;
  font-weight: 400;
}

.task1 {
	display: flex;
	justify-content: space-evenly;
	/* flex-direction: column; */
	width: 100%;
	margin-top: -3rem;
}

.car {
	padding: 0px;
	border: 0px;
	background-color: #f0f2f0;
}

.part_name {
	width: 60%;
	margin-top: -2rem !important;
}
.part_name .MuiInputBase-input {
	width: 100% !important;
}

.lin {
	/* padding-left: 0px;
	font-size: 20px;
	background-color: #f0f2f0;
	padding: 0.5rem;
	width: fit-content;
	text-decoration: none !important;
	color: rgba(0, 0, 0, 0.733); */
	margin: 1rem 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-color: #94949454; */
	color: rgba(0, 0, 0, 0.733) !important;
	/* width: fit-content; */
	text-decoration: none !important;
	/* font-size: 20px; */

	width: 120px;
	height: 120px;
	border-radius: 40px;
	background: #f0f2f0;
	box-shadow: 20px 20px 60px #cccecc, -20px -20px 60px #ffffff;
}
.lin:hover {
	color: rgba(0, 0, 0, 0.733);
	background-color: #0342421f;
	/* width: -webkit-fill-available; */
	transition: background-color 0.4s ease-in-out;
}

.HomePage_main__1haU1.HomePage_container__d1YAB {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  padding: 3rem;
  font-size: 62.5%;
  font-family: "roboto";
  background-color: #fafafa;
  height: 100%;
}

.HomePage_container__d1YAB div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomePage_container__d1YAB div p {
  font-weight: 600;
}

.download-table-xls-button {
	border-width: 0 !important;
}

.spc {
	margin-left: 1rem;
	margin-top: 3rem;
	margin-bottom: 2rem;

	/* display: flex;
    justify-content: space-evenly; */
}
.des {
	display: flex;
	justify-content: space-evenly;
}
.des1 {
	/* margin-bottom: 2rem; */
	margin-right: 2rem;
	display: flex;
	font-size: 14px;
}

/* .des2 {
	margin-bottom: 2rem;
	display: flex;
	font-size: 14px;
} */

.spc .title {
	font-size: 24px;
}
.spc .des {
	margin-top: 3rem;
}
.pa {
	font-size: 16px;
	font-weight: 500;
	margin-right: 1rem;
	margin-bottom: 0;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border-bottom: 1px solid #ddd;
  padding: 14px;
  padding-left: 0px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: rgb(255, 255, 255);
}
#customers td {
  text-align: center;
}
#customers th {
  padding-top: 20px;
  padding-bottom: 12px;
  text-align: center;

  color: rgb(10, 10, 10);
}
.createButton {
  background-color: rgb(131, 2, 2);
  color: aliceblue;
  padding: 6px;
  margin: 0px;
  border-radius: 4px;
  font-weight: lighter;
  font-size: 18px;
  border: 0px;
}
.createButton:hover {
  background-color: rgb(204, 1, 1);
}

.button1 {
  background-color: rgb(3, 116, 63);
  color: aliceblue;
  padding: 4px;
  margin: 0px;
  border-radius: 8px;
  font-weight: lighter;
  font-size: 18px;
  border: 0px;
}
.cancel {
  background-color: rgb(131, 2, 2);
  color: aliceblue;
  padding-right: 14px;
  padding-left: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -15px;
  margin-bottom: 0px;
  border-radius: 3px;
  font-weight: lighter;
  font-size: 18px;
  border: 0px;
}

.create-user-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.specs-container {
  width: 100%;
}
.spec-section {
  display: flex;
}

.spec-section > div {
  flex-basis: 20% !important;
}

.section-input {
  width: 100% !important;
}

.section3-input {
  flex-basis: 20%;
}

.spec-checkbox {
  height: 18px;
  width: 18px;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.spec-header .MuiTextField-root {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.spec-header .MuiFilledInput-root {
  height: 45px !important;
  font-size: 0.8rem !important;
}

.add_to_pir_spec {
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  background-color: #fafafa;
  justify-content: center;
  padding-right: 0.4rem;
}

/* addprocess start  */

.supplier-section {
  margin: 0rem 0rem;
  padding-bottom: 1rem;
  padding: 14px 6px;
  background-color: white;
  margin-bottom: 0px;
  width: 100%;
}

.supplier-section-part {
  display: flex;
}

.supplier-section-part > div {
  flex-basis: 50% !important;
}

.supplier-section .MuiTextField-root {
  width: 100% !important;
}

.btn_save {
  position: absolute;
  color: white;
  background-color: #054a29;
  border-radius: 4px;
  max-width: 157px;
  top: 10px;
  right: 50px;
}
.btn_save:hover {
  background-color: #137547;
  color: #2d2d2d;
  border-radius: 4px;
  transition: background-color 0.4s ease-in-out;
}

/* addprocess end  */

/* process start  */
.MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

.process-field {
  display: flex;
  background-color: white;
}
.process-field div {
  flex-basis: 25% !important;
}
.process-field .add_to_pir {
  display: flex;
  align-items: center;
  background-color: #fafafa;
  margin: 0.6rem 0rem;
  margin-right: 0.5rem;
  justify-content: center;
  padding-right: 0.5rem;
}

.process-field .MuiTextField-root {
  width: 100% !important;
}

.param-field {
  padding-top: 10px;
  display: flex;
  background-color: white;
}

.pfd-movement {
  display: flex;
  align-items: center;
  margin: 0.6rem 0rem;
  background-color: #fafafa;
  margin-bottom: 0.66rem;
  justify-content: center;
  flex-basis: 180px !important;
}

/* process end  */

/* man process */
.m-section .MuiTextField-root {
  margin: 0 !important;
  padding: 0 !important;
}
.m-section .MuiInputLabel-filled.MuiInputLabel-shrink {
  -webkit-transform: translate(5px, 1px) !important;
          transform: translate(5px, 1px) !important;
}

/* Date Field */
.date-field .MuiFilledInput-root {
  background-color: #fafafa !important;
}

.date-field .MuiFilledInput-root:hover {
  background-color: #f5f5f5 !important;
}
.date-field .MuiInputBase-input:focus {
  background-color: #fafafa !important;
}

.date-field .MuiFilledInput-underline:after {
  border: 1px solid #c80202 !important;
}

.date-field .MuiFormLabel-root {
  padding: 0rem !important;
  margin-left: 0.5rem !important;
}

/* Select Field */
.select-field .MuiFilledInput-root {
  background-color: #fafafa !important;
}

.select-field .MuiFilledInput-root:hover {
  background-color: #f5f5f5 !important;
}
.select-field .MuiInputBase-input:focus {
  background-color: #fafafa !important;
}

.select-field .MuiFilledInput-underline:after {
  border: 1px solid #c80202 !important;
}

.select-field .MuiSelect-selectMenu {
  height: 2px !important;
}

/* Text Area */

.textarea-field .MuiFilledInput-root {
  background-color: #fff9db !important;
}

.textarea-field .MuiFilledInput-root:hover {
  background-color: #fff9db !important;
}
/* .textarea-field .MuiInputBase-input:focus {
  background-color: #fafafa !important;
} */

.textarea-field .MuiFilledInput-underline:after {
  border: 1px solid #c80202 !important;
}

.textarea-field .MuiFormLabel-root {
  padding: 0rem !important;
}

.fmeaModalStyle_main_container__1a5FG {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.fmeaModalStyle_main_container__1a5FG .fmeaModalStyle_firstContainer__335Rv {
  display: grid;
  grid-template-columns: repeat(2, minmax(10rem, 1fr));
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.fmeaModalStyle_main_container__1a5FG .fmeaModalStyle_firstContainer__335Rv > * {
  width: 1fr !important;
}

.fmeaModalStyle_main_container__1a5FG .fmeaModalStyle_firstContainer__335Rv .fmeaModalStyle_formControl__2EnjG {
  width: 0.8fr !important;
}

.fmeaModalStyle_main_container__1a5FG .fmeaModalStyle_secondContainer__2r9IZ {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0;
  row-gap: 0;
  height: 290px;
}

/* .main_container .secondContainer > * {
  height: 2fr;
} */

.main-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.process-fmea-container {
  display: grid;
  grid-template-columns: repeat(2, 350px);
  flex: 1 1;
}

.process-fmea-container > div {
  flex-basis: 50% !important;
}

.process-fmea-container1 {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  flex: 1 1;
}
.process-fmea-container1 > div {
  flex-basis: 30% !important;
}

.fmea-input {
  width: 100% !important;
}

.main-button-container {
  display: "flex";
  /* gap: "6rem"; */
  align-items: center;
}

.createCPGrid_cpInputGrid__3sJ0h {
  display: grid;
  grid-template-columns: repeat(6, minmax(220px, 220px)) !important;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  /* justify-content: center; */
}

.subheading-container {
  background-color: #fafafa;
  padding: 1rem;
}

.subheading-primary {
  color: #c80202;
  font-size: 1.4rem;
  letter-spacing: 1.6px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 500;
}

.subheading-secondary {
  font-weight: 400;
  color: black;
}

