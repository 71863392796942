.specs-container {
  width: 100%;
}
.spec-section {
  display: flex;
}

.spec-section > div {
  flex-basis: 20% !important;
}

.section-input {
  width: 100% !important;
}

.section3-input {
  flex-basis: 20%;
}

.spec-checkbox {
  height: 18px;
  width: 18px;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.spec-header .MuiTextField-root {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.spec-header .MuiFilledInput-root {
  height: 45px !important;
  font-size: 0.8rem !important;
}

.add_to_pir_spec {
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  background-color: #fafafa;
  justify-content: center;
  padding-right: 0.4rem;
}

/* addprocess start  */

.supplier-section {
  margin: 0rem 0rem;
  padding-bottom: 1rem;
  padding: 14px 6px;
  background-color: white;
  margin-bottom: 0px;
  width: 100%;
}

.supplier-section-part {
  display: flex;
}

.supplier-section-part > div {
  flex-basis: 50% !important;
}

.supplier-section .MuiTextField-root {
  width: 100% !important;
}

.btn_save {
  position: absolute;
  color: white;
  background-color: #054a29;
  border-radius: 4px;
  max-width: 157px;
  top: 10px;
  right: 50px;
}
.btn_save:hover {
  background-color: #137547;
  color: #2d2d2d;
  border-radius: 4px;
  transition: background-color 0.4s ease-in-out;
}

/* addprocess end  */

/* process start  */
.MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

.process-field {
  display: flex;
  background-color: white;
}
.process-field div {
  flex-basis: 25% !important;
}
.process-field .add_to_pir {
  display: flex;
  align-items: center;
  background-color: #fafafa;
  margin: 0.6rem 0rem;
  margin-right: 0.5rem;
  justify-content: center;
  padding-right: 0.5rem;
}

.process-field .MuiTextField-root {
  width: 100% !important;
}

.param-field {
  padding-top: 10px;
  display: flex;
  background-color: white;
}

.pfd-movement {
  display: flex;
  align-items: center;
  margin: 0.6rem 0rem;
  background-color: #fafafa;
  margin-bottom: 0.66rem;
  justify-content: center;
  flex-basis: 180px !important;
}

/* process end  */

/* man process */
.m-section .MuiTextField-root {
  margin: 0 !important;
  padding: 0 !important;
}
.m-section .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(5px, 1px) !important;
}

/* Date Field */
.date-field .MuiFilledInput-root {
  background-color: #fafafa !important;
}

.date-field .MuiFilledInput-root:hover {
  background-color: #f5f5f5 !important;
}
.date-field .MuiInputBase-input:focus {
  background-color: #fafafa !important;
}

.date-field .MuiFilledInput-underline:after {
  border: 1px solid #c80202 !important;
}

.date-field .MuiFormLabel-root {
  padding: 0rem !important;
  margin-left: 0.5rem !important;
}

/* Select Field */
.select-field .MuiFilledInput-root {
  background-color: #fafafa !important;
}

.select-field .MuiFilledInput-root:hover {
  background-color: #f5f5f5 !important;
}
.select-field .MuiInputBase-input:focus {
  background-color: #fafafa !important;
}

.select-field .MuiFilledInput-underline:after {
  border: 1px solid #c80202 !important;
}

.select-field .MuiSelect-selectMenu {
  height: 2px !important;
}

/* Text Area */

.textarea-field .MuiFilledInput-root {
  background-color: #fff9db !important;
}

.textarea-field .MuiFilledInput-root:hover {
  background-color: #fff9db !important;
}
/* .textarea-field .MuiInputBase-input:focus {
  background-color: #fafafa !important;
} */

.textarea-field .MuiFilledInput-underline:after {
  border: 1px solid #c80202 !important;
}

.textarea-field .MuiFormLabel-root {
  padding: 0rem !important;
}
