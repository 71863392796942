@import url("https://fonts.googleapis.com/css2?family=Varela&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", "Varela", sans-serif;
  position: relative;
  overscroll-behavior-x: none;
  /* background-color: #f0f2f0; */
}

section[disabled] {
  pointer-events: none;
  opacity: 0.4;
  background: #f5f5f5;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media print {
  @page {
    size: A2 landscape;
  }
  .no-print {
    display: none;
  }
}
